export const getUserData = () =>
  typeof window !== `undefined` &&
  (localStorage.getItem('user') || sessionStorage.getItem('user')) &&
  JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'))

export const clearUserData = () => {
  localStorage.removeItem('user')
  sessionStorage.removeItem('user')
}

export const createOrUpdateUserData = newUserData => {
  const storageName =
    newUserData.rememberUser || (getUserData() && getUserData().rememberUser)
      ? localStorage
      : sessionStorage

  const data = {
    ...getUserData(),
    ...newUserData,
  }

  storageName.setItem('user', JSON.stringify(data))
}
