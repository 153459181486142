import React from 'react';

import styles from '../sass/components/checkbox.module.scss';

export default ({ value, name, required = false, onChange, children }) => (
  <label className={styles.checkbox}>
    <input
      type="checkbox"
      className={styles.checkbox__input}
      name={name || value}
      required={required}
      onChange={onChange && (event => onChange(event))}
      value={value}
    />
    <span className={styles.checkbox__caption}>{children || value}</span>
    <p className="validation">WRONG</p>
  </label>
)
