import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-294 415.9 7 10" style={{marginLeft: '10px'}}>
    <path
      fill="currentColor"
      d="M-291.1,415.9c-0.9,0-1.9,0-2.9,0c1.4,1.7,2.7,3.3,4.1,5c-1.4,1.7-2.7,3.3-4.1,5c0.9,0,1.9,0,2.9,0
	c1.3-1.5,2.6-3.2,4.1-5C-288.2,419.4-289.7,417.6-291.1,415.9z"
    />
  </svg>
)
